/**
 * Some files uploaded by users use extension `.jpe` which is not defined as "official" for JPEG files.
 * I was unable to find a single source of information that would explain their role and purpose
 * but all the mentions of it agreed that they are valid JPEGs and can in turn be renamed by replacing
 * ".jpe" with ".jpeg".
 * Most of image editors, browser, etc. already recognize that extension and treat it as any other JPEG file
 * but it's problematic for the Propview app where image list component (from antd) does not
 * and causes concern for the client.
 */
export const getFileNameWithFixedJpe = (fileName: string): string => {
  const lastDotSepIdx = fileName.lastIndexOf('.');
  if (lastDotSepIdx >= 0) {
    const [fileNameWithoutExtension, extension] = [fileName.slice(0, lastDotSepIdx), fileName.slice(lastDotSepIdx + 1)];
    if (extension.toLowerCase() === 'jpe') {
      return `${fileNameWithoutExtension}.jpeg`;
    }
  }

  return fileName;
};
