import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { azureObjectUpload } from '../api/azureObjectUpload';

import { getAzureSignedObjectLink } from '../api/getAzureSignedObjectLink';
import { getFileNameWithFixedJpe } from '../../../common/utils';

export const useAzureObjectPhotoUpload = () => {
  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, File>(async (file) => {
    const { url, query } = await getAzureSignedObjectLink();

    const fileName = `${uuidv4()}-${getFileNameWithFixedJpe(file.name!)}`;
    await azureObjectUpload(new File([file!], fileName!), `${url}?${query}`);

    return `${url}objects/${fileName}`;
  });

  return {
    data,
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};
